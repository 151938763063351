import GMaps from 'gmaps';

(() => {
  window.$ = window.jQuery;
  window.GMaps = GMaps;
  window.addEventListener('load', function () {
    require('./parts/Menu');
  });

  window.initMap = function () {
    const $map = $('.md-gmap');

    const markers = $map.data('pointers');

    if (markers.length > 0) {
      const map = new GMaps({
        el: '.' + $map.attr('class'),
        zoom: 15,
        streetViewControl: false,
        disableDefaultUI: false,
        center: {
            lat: +markers[0].latitude,
            lng: +markers[0].longitude,
        }
      });

      const bounds = new google.maps.LatLngBounds();


      markers.forEach(market => {
        const markerLat = +market.latitude;
        const markerLng = +market.longitude;

        map.addMarker({
          lat: markerLat,
          lng: markerLng,
          title: market.post_title,
          icon: $map.data('image'),
          infoWindow: {
            content: generateInfoWindowContent(market),
          },
        });

        bounds.extend(new google.maps.LatLng(markerLat, markerLng));
      });

      map.fitBounds(bounds);
    }
  };

  function generateInfoWindowContent(market) {
    const expertiseContent = market.expertise ? `<p style="font-weight: 500; margin-bottom: 0">${ market.expertise }</p>` : '';
    return `
      <div style="display:flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
        <a href="${ market.link }" target="_blank">
          <span style="display:block; width: 70px; height: 70px; border-radius: 50rem; margin-bottom: .5rem; overflow: hidden">${ market.image }</span>
        </a>
        <a href="${ market.link }" target="_blank" style="text-decoration: none; text-align: center">
          <p style="color: #85005f; font-weight: 600; margin-bottom: .5rem">${ market.post_title }</p>
        </a>
        ${ expertiseContent }
      </div>
    `;
  }
})();
